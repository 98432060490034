import { useEffect, useState } from 'react';

import { AccordionDetails, AccordionSummary, Skeleton } from '@mui/material';
import clsx from 'clsx';
import { findIndex, isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import {
  AggregateFilterDisplay,
  IndividualAggregate
} from 'API/types/search.types';
import VirtualizedList from 'common/VirtualizedList';
import { Accordion } from 'components';
import { ProductAttribute } from 'generated/graphql';
import { ArrowDropDownIcon } from 'icons';
import { useBranchContext } from 'providers/BranchProvider';
import SearchSubfilter from 'pages/Search/sub/SearchFilters/SearchSubfilter';
import repeatMap from 'utils/repeatMap';

/**
 * Config
 */
export const FILTER_KEY = 'in_stock_location';

/**
 * Types
 */
export type SearchFilterListProps = {
  index: number;
  expanded: boolean;
  filter?: AggregateFilterDisplay;
  subfilters: IndividualAggregate[];
  loading: boolean;
  skeletonTotal: number;
  selectedFilters: {
    attributeType: string;
    attributeValue: string;
  }[];
  handleToggle: (changedFilter: ProductAttribute) => void;
};

/**
 * Component
 */
function SearchFilterList(props: SearchFilterListProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { shippingBranch, shippingBranchLoading } = useBranchContext();

  /**
   * State
   */
  const [isExpanded, setIsExpanded] = useState(props.expanded);

  /**
   * Consts
   */
  const stockDisabled =
    shippingBranch?.isPricingOnly &&
    props.filter?.filterKey === ` ${FILTER_KEY}`;
  const stockText =
    props.filter?.filterKey === FILTER_KEY
      ? `${t('search.inStockAt')} ${shippingBranch?.name ?? ''}`
      : '';

  /**
   * Callbacks
   */
  const getChecked = (index: number) =>
    findIndex(props.selectedFilters, (currentFilter) =>
      isEqual(currentFilter, {
        attributeType: props.filter?.filterKey?.replace(/s$/, ''),
        attributeValue: props.subfilters?.[index]?.value
      })
    ) !== -1;

  /**
   * Effects
   */
  useEffect(() => {
    if (props.filter?.filterKey === FILTER_KEY && !props.subfilters.length) {
      props.subfilters.push({ count: 0, value: stockText });
    }
  }, [props.filter, props.subfilters, stockText]);

  /**
   * Render
   */
  return (
    <Accordion
      key={props.filter?.filterKey}
      type="filter"
      onChange={(_, isExpanded) => setIsExpanded(isExpanded)}
      expanded={isExpanded}
      data-testid={`search-filter-list-${props.filter?.filterKey}`}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="filter-content"
        id="filter-header"
      >
        <p className={clsx({ 'text-primary-3-100': stockDisabled })}>
          {props.filter?.filterName}
        </p>
      </AccordionSummary>
      <AccordionDetails className="flex-col !px-2 !pb-6">
        {props.loading &&
          repeatMap(props.skeletonTotal, (i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              className="w-full h-8 mb-2"
            />
          ))}
        {!props.loading && (
          <VirtualizedList
            key={props.subfilters.length}
            defaultItemSize={40}
            maxHeight={200}
            dataArray={props.subfilters}
            renderItem={(subfilter, idx) => (
              <SearchSubfilter
                key={idx}
                filter={props.filter}
                subfilter={subfilter ?? { count: 0, value: stockText }}
                checked={getChecked(idx)}
                handleToggle={props.handleToggle}
                loading={shippingBranchLoading}
                stockText={stockText}
                filterDisabled={stockDisabled}
              />
            )}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default SearchFilterList;
