import { Box, Container, Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CustomerPartNumber from 'common/CustomerPartNumber';
import { Button, Loader } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { BranchIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';
import EnvironmentalOptions from 'pages/Product/sub/EnvironmentalOptions';
import FeaturesAndBenefits from 'pages/Product/sub/FeaturesAndBenefits';
import PackageDimensions from 'pages/Product/sub/PackageDimensions';
import ProductAddToListButton from 'pages/Product/sub/ProductAddToListButton';
import ProductAvailabilityChip from 'pages/Product/sub/ProductAvailabilityChip';
import ProductCartButton from 'pages/Product/sub/ProductCartButton';
import ProductCodes from 'pages/Product/sub/ProductCodes';
import ProductImage from 'pages/Product/sub/ProductImage';
import ProductOverview from 'pages/Product/sub/ProductOverview';
import ProductPricing from 'pages/Product/sub/ProductPricing';
import ProductTabs from 'pages/Product/sub/ProductTabs';
import TechnicalDocuments from 'pages/Product/sub/TechnicalDocuments';
import TechnicalSpecifications from 'pages/Product/sub/TechnicalSpecifications';
import { useAuthContext } from 'providers/AuthProvider';
import { useBranchContext } from 'providers/BranchProvider';

/**
 * Component
 */
function ProductPage() {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const { setBranchSelectOpen, shippingBranch, setProductId } =
    useBranchContext();
  const { loading, product } = useProductPageContext();

  /**
   * Callbacks
   */
  // 🟤 Cb - Branch change
  const handleBranchChange = () => {
    setBranchSelectOpen(true);
    setProductId(product!.productId);
  };

  /**
   * Render
   */
  if (loading) {
    return <Loader backdrop testId="pdp-loading" />;
  }
  return (
    <Container
      maxWidth="lg"
      disableGutters={isSmallScreen}
      data-testid="pdp-page"
    >
      <div className="flex flex-nowrap">
        <Hidden mdDown>
          <div className="basis-1/2 flex flex-col justify-start items-end border-secondary-3-100 border-r">
            <div className="mr-8">
              <ProductAddToListButton />
            </div>
            <div
              className="w-full mt-24 flex justify-center items-center"
              data-testid="product-image-container"
            >
              <ProductImage />
            </div>
          </div>
        </Hidden>
        <div className="basis-1/2 flex flex-col flex-nowrap md:basis-full">
          <div className="px-8 md:px-6" data-testid="product-detail-container">
            <h5
              className="text-primary-3-100 text-xl md:pt-4"
              data-testid="product-manufacturer-name"
            >
              {product?.manufacturerName}
            </h5>
            <h4
              className="text-primary-1-100 text-2xl font-medium"
              data-testid="product-name"
            >
              {product?.name}
            </h4>
            <div className="flex flex-col w-full">
              {Boolean(product?.manufacturerNumber) && (
                <h5
                  className="pt-2 text-secondary-2-100 text-xl"
                  data-testid="product-manufacturer-number"
                >
                  {`${t('product.mfr')} ${product!.manufacturerNumber}`}
                </h5>
              )}
              <Hidden mdDown>
                <CustomerPartNumber // from Search, might need update with Search refactor
                  partNumber={product?.customerProductId}
                  stylingForPage="PDP"
                />
              </Hidden>
            </div>
            <Hidden mdUp>
              <div>
                <CustomerPartNumber // from Search, might need update with Search refactor
                  partNumber={product?.customerProductId}
                  stylingForPage="PDP"
                />
              </div>
              <div className="pt-6 flex flex-col justify-center items-end">
                <ProductAddToListButton />
                <div className="self-center flex justify-center">
                  <ProductImage />
                </div>
              </div>
            </Hidden>
            <div className="py-6 flex items-center">
              <ProductPricing />
            </div>
            <div className="flex justify-start gap-4 md:flex-col">
              {Boolean(
                shippingBranch?.isPricingOnly ||
                  product?.branchAvailableQty ||
                  (!product?.totalAvailableQty && !product?.branchAvailableQty)
              ) && (
                <div className="w-64 md:w-full">
                  <ProductAvailabilityChip />
                </div>
              )}
              {Boolean(
                authState?.isAuthenticated &&
                  product?.totalAvailableQty &&
                  !shippingBranch?.isPricingOnly
              ) && (
                <div className="bg-common-white flex flex-row items-center gap-4 w-64 h-14 p-2 rounded-sm shadow-lg md:w-full">
                  <Button
                    color="lightBlue"
                    data-testid="find-at-other-branches-button"
                    size="sm"
                    kind="text"
                    iconStart={
                      <BranchIcon className="w-[30px] h-[30px] mr-2" />
                    }
                    disabled={!product}
                    onClick={handleBranchChange}
                    className="font-medium"
                  >
                    {t('product.checkNearByBranches')}
                  </Button>
                </div>
              )}
            </div>
            <div className="py-6">
              <ProductCartButton />
            </div>
          </div>
          <Hidden mdUp>
            <EnvironmentalOptions />
          </Hidden>
          <div className="px-6 pb-6">
            <Hidden mdUp>
              <Box pb={3} data-testid="product-details-accordian">
                <TechnicalDocuments />
                <ProductCodes />
                <ProductOverview />
                <FeaturesAndBenefits />
                <TechnicalSpecifications />
                <PackageDimensions />
              </Box>
            </Hidden>
            <Hidden mdDown>
              <TechnicalDocuments />
              <ProductCodes />
            </Hidden>
            <Hidden mdDown>
              <EnvironmentalOptions />
            </Hidden>
          </div>
        </div>
      </div>
      <ProductTabs />
    </Container>
  );
}

export default ProductPage;
