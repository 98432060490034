// istanbul ignore file
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Hidden } from '@mui/material';
import clsx from 'clsx';
import { isNil, kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import slugify from 'react-slugify';

import { useApiGetProductsPricing } from 'API/products.api';
import { useApiSearch } from 'API/search.api';
import { ProductPricing } from 'API/types/products.types';
import {
  Aggregate,
  SearchProduct,
  SearchRequest
} from 'API/types/search.types';
import { useCategoriesContext } from 'Categories/CategoriesProvider';
import BackToTop from 'common/BackToTop';
import { useHeaderContext } from 'common/Header/HeaderProvider';
import { AdvancedToolTip, Button, Pagination } from 'components';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useDomainInfo } from 'hooks/useDomainInfo';
import useScreenSize from 'hooks/useScreenSize';
import { WarningIcon } from 'icons';
import FeaturedSearch from 'pages/Search/sub/FeaturedSearch';
import SearchBreadcrumbs from 'pages/Search/sub/SearchBreadcrumbs';
import SearchFilters from 'pages/Search/sub/SearchFilters/index';
import SearchResult from 'pages/Search/sub/SearchResult/index';
import SearchResultSkeleton from 'pages/Search/sub/SearchResult/SearchResultSkeleton';
import SearchNoResults from 'pages/Search/sub/SearchResult/SearchNoResults';
import SearchVisitorAlert from 'pages/Search/sub/SearchVisitorAlert';
import useSearchQueryParams from 'pages/Search/util/useSearchQueryParams';
import { useAuthContext } from 'providers/AuthProvider';
import { useBranchContext } from 'providers/BranchProvider';
import { useCartContext } from 'providers/CartProvider';
import { useListsContext } from 'providers/ListsProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import {
  trackSearchPageOneConversionAction,
  trackProductViewAction
} from 'utils/analytics';
import repeatMap from 'utils/repeatMap';

/**
 * Config
 */
export const SEARCH_CATEGORY_PATH = '/search/category/';
export const STOCK_FILTER = 'inStockLocation|in_stock_location';
const PAGE_SIZE = 24;
const HEATING_COOLING = ['heating and cooling', 'heating & cooling'];
const WATER_HEATERS = ['water heaters', 'water heater'];
const TOOL_RENTAL = ['tool rental'];

/**
 * Component
 */
function Search() {
  /**
   * Custom components
   */
  const history = useHistory();
  const { pathname } = useLocation();
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const [params, setParams] = useSearchQueryParams();
  const { engine } = useDomainInfo();
  const { criteria = '', page = 1, categories = [], filters = [] } = params;
  useDocumentTitle(t('common.businessPortal'));

  /**
   * Ref
   */
  const criteriaRef = useRef(criteria);
  // must be placed here for updating the value to prevent stale data
  useEffect(() => {
    criteriaRef.current = criteria;
  }, [criteria]);

  /**
   * Context
   */
  const { authState, user, oktaAuth } = useAuthContext();
  const { shippingBranch } = useBranchContext();
  const { disableAddToCart } = useCartContext();
  const { categories: productCategories, categoriesLoading } =
    useCategoriesContext();
  const {
    setSearchPage,
    setTrackedSearchTerm,
    setPageIndex,
    trackedSearchTerm
  } = useHeaderContext();
  const { selectedAccounts } = useSelectedAccountsContext();
  const { callGetAssociatedLists } = useListsContext();

  /**
   * State
   */
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortedAggregates, setSortedAggregates] = useState<Aggregate[]>([]);
  const [pricingData, setPricingData] = useState<ProductPricing[]>([]);

  /**
   * Memos
   */
  // 🔵 Memo - criteria: water cooling
  const isHeatingCooling = useMemo(
    () => HEATING_COOLING.includes(criteria.toLowerCase()),
    [criteria]
  );
  // 🔵 Memo - criteria: water heater
  const isWaterHeaters = useMemo(
    () => WATER_HEATERS.includes(criteria.toLowerCase()),
    [criteria]
  );
  // 🔵 Memo - criteria: tool rental
  const isToolRental = useMemo(
    () => TOOL_RENTAL.includes(criteria.toLowerCase()),
    [criteria]
  );

  /**
   * Consts
   */
  const filterCount = categories.length + filters.length;
  const resultString = t('common.result', { count: totalCount });

  /**
   * API
   */
  // 🟣 POST - search
  const {
    data: searchData,
    call: searchCall,
    called: searchCalled,
    loading: searchLoading
  } = useApiSearch();
  // 🟣 GET - products pricing
  const {
    call: pricingCall,
    loading: pricingLoading,
    called: pricingCalled
  } = useApiGetProductsPricing();

  /**
   * Callbacks
   */
  //  🟤 Cb - Call Pricing

  const handleCallPricing = useCallback(
    async (products?: SearchProduct[]) => {
      const auth = await oktaAuth?.isAuthenticated();
      const getProducts = products ?? searchData?.products;
      if (auth && getProducts?.length) {
        const ids = getProducts.map(({ id }) => id);
        const res = await pricingCall(ids, criteriaRef.current);
        if (res?.data?.products && res.data.searchID === criteriaRef.current) {
          setPricingData(res.data.products);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchData?.products, oktaAuth]
  );
  // 🟤 Cb - Call Search
  const handleSearchCall = async (body: Partial<SearchRequest> = {}) => {
    const res = await searchCall({
      term: criteria.replace('"', '\\"'),
      page: Number(page),
      size: PAGE_SIZE,
      categoryLevel: categories.length,
      categories,
      filters,
      engine,
      state: selectedAccounts.shipToErpAccount?.state?.toLowerCase() ?? '',
      ...body
    });
    if (!res?.data) {
      return;
    }
    setTotalCount(res.data.pagination.totalItemCount);
    //set aggregates in order based on aggregate sortOrder value
    const sortedAggregates = sortAggregates(
      res.data.aggregates.builtAggregates
    );
    setSortedAggregates(sortedAggregates);
    // Get products part numbers (when adding to list, only the part number is used)
    const productsIds = res.data.products.map(({ partNumber }) => partNumber);
    callGetAssociatedLists({ products: productsIds });
    handleCallPricing(res.data.products);
  };
  // 🟤 Cb - pagination
  const handlePagination = (newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    handleSearchCall({ page: newPage });
    setParams({ ...params, page: newPage.toString() });
  };
  // 🟤 Cb - search string display
  const searchString = (value: string) => {
    let newSearchValue: string = '';
    if (value) {
      newSearchValue = value.replaceAll(/(")\1+/g, '"');
    }
    return `${newSearchValue} (${totalCount} ${resultString})`;
  };
  // 🟤 Cb = Result click
  const handleResultClick = (pageIndex: number) => {
    setTrackedSearchTerm(criteria);
    setSearchPage(Number(page));
    setPageIndex(pageIndex);
    trackProductViewAction({
      pageIndex: pageIndex,
      pageNumber: Number(page),
      searchIndex: pageIndex + 1,
      searchTerm: criteria,
      authenticated: authState?.isAuthenticated,
      userEmail: user?.email ?? '',
      product: searchData?.products?.[pageIndex],
      timestamp: new Date().toISOString(),
      brand: searchData?.products?.[pageIndex]?.manufacturerName ?? ''
    });
    if ((page === '1' || page === 1) && !isNil(trackedSearchTerm)) {
      trackSearchPageOneConversionAction({
        billTo: selectedAccounts.billToErpAccount?.erpAccountId ?? '',
        searchTerm: criteria,
        conversionType: 'Product Detail',
        userEmail: user?.email ?? ''
      });
    }
  };
  // 🟤 Cb - Aggregate sorting
  const sortAggregates = (aggregationResults: Aggregate[]) =>
    aggregationResults.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));

  // 🟡 Effect - main api call
  useEffect(() => {
    handleSearchCall();
    // using stringify because we don't want infinite loop since it can't check for array content
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.criteria, JSON.stringify(params.categories)]);
  // 🟡 Effect - Call product pricing
  useEffect(() => {
    if (pricingCalled) {
      handleCallPricing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingBranch]);
  // 🟡 Effect - fix params
  useEffect(() => {
    if (!isNil(page) && Number(page) < 0) {
      setParams({ ...params, page: '1' });
    }
  }, [history, page, params, setParams]);
  // 🟡 Effect - update param on filter
  useEffect(() => {
    if (shippingBranch?.isPricingOnly && filters.includes(STOCK_FILTER)) {
      const myFilters = filters.filter((filter) => filter !== STOCK_FILTER);
      setParams({ ...params, page: '1', filters: myFilters });
    }
  }, [shippingBranch?.isPricingOnly, filters, params, setParams]);
  // 🟡 Effect - update param on cateigories
  useEffect(() => {
    if (
      !params.criteria &&
      !params.categories &&
      !params.filters &&
      !categoriesLoading &&
      pathname.startsWith(SEARCH_CATEGORY_PATH) &&
      pathname !== SEARCH_CATEGORY_PATH
    ) {
      const pathCategories = pathname.split('/');
      if (pathCategories.length > 3) {
        const matchedCategories = [''];
        productCategories?.map(
          (category) =>
            category?.name &&
            slugify(category?.name) === pathCategories[3] &&
            matchedCategories.push(category?.name) &&
            pathCategories[4] &&
            category?.children?.map(
              (subcategory) =>
                subcategory?.name &&
                slugify(subcategory?.name) === pathCategories[4] &&
                matchedCategories.push(subcategory?.name) &&
                pathCategories[5] &&
                subcategory?.children?.map(
                  (subsubcat) =>
                    subsubcat?.name &&
                    slugify(subsubcat?.name) === pathCategories[5] &&
                    matchedCategories.push(subsubcat?.name)
                )
            )
        );
        setParams({ ...params, categories: matchedCategories });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoriesLoading,
    pathname,
    params.criteria,
    params.categories,
    params.filters,
    productCategories
  ]);
  // 🟡 Effect - scroll to the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Components
   */
  const PaginationElement = () => (
    <Pagination
      count={Math.ceil(
        (searchData?.pagination?.totalItemCount || 0) / PAGE_SIZE
      )}
      current={Number(page)}
      ofText={t('common.of')}
      onChange={handlePagination}
      onPrev={handlePagination}
      onNext={handlePagination}
      testId="pagination"
    />
  );

  /**
   * Render
   */
  if (criteria === null) {
    return <Redirect to="/" />;
  }
  if (isWaterHeaters) {
    return <Redirect to="/category/water-heaters" />;
  }
  if (isToolRental) {
    return <Redirect to="/tool-rental" />;
  }
  if (isHeatingCooling) {
    return <Redirect to="/category/heating-cooling" />;
  }
  if (!searchLoading && !searchData?.products?.length) {
    return <SearchNoResults searchTerm={criteria} />;
  }
  return (
    <>
      <Hidden mdUp>
        <SearchFilters
          onChange={handleSearchCall}
          loading={searchCalled && searchLoading}
          count={searchData?.pagination?.totalItemCount || 0}
          filters={sortedAggregates}
          filtersOpen={filtersOpen}
          onShowHide={setFiltersOpen}
        />
      </Hidden>
      <div className="flex-1 bg-common-white pb-24 md:pb-6">
        {!authState?.isAuthenticated && !searchLoading && (
          <SearchVisitorAlert />
        )}
        <Hidden mdUp>
          <div className="m-6 flex items-center">
            <div className="basis-0 flex-grow max-w-full">
              {!searchLoading && <SearchBreadcrumbs />}
            </div>
            <div
              className="w-full flex flex-wrap justify-end items-center basis-0 flex-grow"
              data-testid={kebabCase(
                `${categories[categories.length - 1] || criteria}-title`
              )}
            >
              <span className="text-base font-bold text-right">
                &nbsp;
                {categories.length
                  ? categories[categories.length - 1]
                  : criteria}
              </span>
              <span className="block text-base font-bold text-right">
                &nbsp; ({totalCount} {resultString})
              </span>
            </div>
          </div>
        </Hidden>
        <div className="max-w-7xl mx-auto flex gap-12 px-6 py-12 mt-0 md:!px-8 md:py-0">
          <Hidden mdDown>
            <div className="basis-1/4 flex flex-col items-end">
              <SearchFilters
                onChange={handleSearchCall}
                loading={searchLoading}
                count={searchData?.pagination?.totalItemCount || 0}
                filters={sortedAggregates}
                filtersOpen={filtersOpen}
                onShowHide={setFiltersOpen}
              />
            </div>
          </Hidden>
          <div className="basis-3/4 md:basis-full">
            <FeaturedSearch />
            <Hidden mdDown>
              {!searchLoading && <SearchBreadcrumbs />}
              <div className="mt-4 mb-10 flex items-center">
                <div
                  className="basis-1/2"
                  data-testid={kebabCase(
                    categories.length
                      ? `${categories[categories.length - 1]}-title`
                      : `${criteria}-title`
                  )}
                >
                  <span className="text-xl font-bold">
                    {searchString(
                      criteria || categories[categories.length - 1]
                    )}
                  </span>
                </div>
                <div
                  className="basis-1/2 flex justify-end"
                  data-testid="pagination-counter-top"
                >
                  {Boolean(
                    !searchLoading && searchData?.pagination?.totalItemCount
                  ) && <PaginationElement />}
                </div>
              </div>
            </Hidden>
            <div className="flex flex-wrap" data-testid="search-container">
              {searchLoading ? (
                repeatMap(3, (i) => (
                  <div
                    className="basis-1/4 flex flex-col md:[&:not(:last-child)]:mb-4"
                    key={i}
                  >
                    <SearchResultSkeleton
                      testid={`search-result-skeleton-${i}`}
                    />
                  </div>
                ))
              ) : (
                <>
                  <Hidden mdUp>
                    <div
                      className={clsx('basis-full flex-grow', {
                        'md:mb-32': disableAddToCart
                      })}
                    >
                      <AdvancedToolTip
                        title="Warning"
                        text={t('cart.maxLimitToolTip')}
                        icon={<WarningIcon />}
                        placement="bottom"
                        disabled={disableAddToCart}
                      >
                        <Button
                          size="sm"
                          kind="outline"
                          fullWidth
                          onClick={() => setFiltersOpen(true)}
                          className="mb-6"
                          data-testid="filter-count-mob"
                        >
                          {t('common.filter')}
                          {filterCount > 0 && ` (${filterCount})`}
                        </Button>
                      </AdvancedToolTip>
                    </div>
                  </Hidden>
                  {searchData?.products?.map((product, i) => (
                    <div
                      className="basis-1/4 w-1/4 flex flex-col md:basis-full [&:not(:last-child)]:mb-8"
                      key={i}
                    >
                      <SearchResult
                        product={product}
                        pricing={pricingData}
                        pricingLoading={pricingLoading}
                        index={i}
                        onClick={() => handleResultClick(i)}
                      />
                      <Hidden mdUp>
                        <div className="pt-6">
                          <hr className="border-secondary-3-100" />
                        </div>
                      </Hidden>
                    </div>
                  ))}
                </>
              )}
            </div>
            {Boolean(
              !searchLoading && searchData?.pagination?.totalItemCount
            ) && (
              <div
                className="flex justify-center mt-8 mb-0 md:my-12"
                data-testid="pagination-counter-bottom"
              >
                <PaginationElement />
              </div>
            )}
          </div>
        </div>
      </div>
      {!searchLoading && isSmallScreen && <BackToTop />}
    </>
  );
}

export default Search;
