import clsx from 'clsx';

import { List } from 'API/types/lists.types';
import { CheckboxInput } from 'components';
import ListFullWarning from 'common/ListsDrawer/sub/ListFullWarning';
import { MAX_LIST_ITEMS } from 'providers/libs/ListsProvider';

/**
 * Types
 */
type ListDrawerItemProps = {
  list: List;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
};

/**
 * Component
 */
function ListDrawerItem(props: ListDrawerItemProps) {
  /**
   * State
   */
  const listItemDisabled =
    props.disabled ||
    (!props.selected && (props.list.listLineItemsSize ?? 0) >= MAX_LIST_ITEMS);

  const listFull = (props.list.listLineItemsSize ?? 0) >= MAX_LIST_ITEMS;

  /**
   * Callbacks
   */
  const onClick = () => !listItemDisabled && props.onClick();

  /**
   * Render
   */
  return (
    <div
      className={clsx('w-full p-3 flex gap-3 select-none', {
        'cursor-pointer': !listItemDisabled,
        'bg-primary-2-100/10': props.selected && !listItemDisabled,
        'bg-secondary-2-100/10': props.selected && listItemDisabled
      })}
      data-testid={`list-item-${props.list.id}`}
      onClick={onClick}
    >
      <CheckboxInput
        color="lightBlue"
        checked={props.selected}
        disabled={listItemDisabled}
      />
      <div className="w-full flex justify-between">
        <span
          className={clsx('text-primary-3-100 text-base truncate', {
            'text-secondary-3-100': listItemDisabled
          })}
        >
          {props.list.name} ({props.list.listLineItemsSize ?? 0})
        </span>
        {listFull && <ListFullWarning showText={true} />}
      </div>
    </div>
  );
}

export default ListDrawerItem;
