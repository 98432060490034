import { useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { uniqBy } from 'lodash-es';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { FixedSizeList } from 'react-window';

import useSearchDrawerTransitions from 'common/Header/lib/useSearchDrawerTransitions';
import ListFullWarning from 'common/ListsDrawer/sub/ListFullWarning';
import IconButton from 'components/IconButton';
import InputBase from 'components/TextInput/InputBase';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'icons';
import { MAX_LIST_ITEMS } from 'providers/libs/ListsProvider';

/**
 * Config
 */
export const LIST_CUTOFF = 5;
const MAX_LIST_HEIGHT = 256;
const LIST_ITEM_HEIGHT = 48;

/**
 * Types
 */
export type AutocompleteOptionType = {
  label: string;
  value: string;
  listSize?: number;
};
export type AutocompleteProps = {
  allowSearchValue?: boolean;
  disabled?: boolean;
  maxDropdownHeight?: number;
  onBlur?: () => void;
  onChange: (val: string) => void;
  options: AutocompleteOptionType[];
  testId?: string;
  value: string;
};

/**
 * Component
 */
function Autocomplete(props: AutocompleteProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const transitions = useSearchDrawerTransitions();

  /**
   * Refs
   */
  const inputRef = useRef<HTMLInputElement>(null);
  const inputSizing = inputRef.current?.getBoundingClientRect();

  /**
   * States
   */
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  /**
   * Memo
   */
  // 🔵 Memo - Selected value
  const selected = useMemo(
    () => props.options.find((opt) => opt.value === props.value) ?? null,
    [props.value, props.options]
  );
  // 🔵 Memo - Options
  const options = useMemo(() => {
    // unique
    const uniqueOptions = uniqBy(props.options, ({ label }) => label);
    return uniqueOptions.filter(
      ({ label, value }) =>
        (value.toLowerCase().includes(search.toLowerCase()) &&
          props.allowSearchValue) ||
        label.toLowerCase().includes(search.toLowerCase())
    );
  }, [props.allowSearchValue, props.options, search]);
  // max height
  const maxListHeight = Math.min(
    props.maxDropdownHeight || MAX_LIST_HEIGHT,
    LIST_ITEM_HEIGHT * options.length
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - Open
  const openSearch = () => {
    inputRef.current?.focus();
    setOpen(true);
  };
  // 🟤 Cb - Close
  const closeSearch = () => {
    inputRef.current?.blur();
    props.onBlur?.();
    setOpen(false);
  };
  // 🟤 Cb - Toggle
  const toggleSearch = () => (open ? closeSearch() : openSearch());
  // 🟤 Cb - Select
  const onItemSelect = (item: AutocompleteOptionType) => () => {
    if ((item.listSize ?? 0) >= MAX_LIST_ITEMS) {
      return;
    }
    setSearch('');
    props.onChange(item.value);
    closeSearch();
  };

  /**
   * Render
   */
  return (
    <div data-testid={props.testId} className="relative">
      <div className={clsx('relative', { 'z-[100]': open })}>
        <InputBase
          ref={inputRef}
          value={open ? search : selected?.label ?? ''}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={openSearch}
          disabled={props.disabled}
          testId={`${props.testId}-input`}
          className="!pr-12 "
        />
      </div>
      <div className={clsx('absolute top-1 right-1', { 'z-[100]': open })}>
        <IconButton
          color="gray"
          onClick={toggleSearch}
          data-testid={`${props.testId}-button`}
          disabled={props.disabled}
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </div>
      {open && (
        <div
          onClick={closeSearch}
          data-testid={`${props.testId}-backdrop`}
          className="fixed top-0 left-0 w-screen h-screen z-10 !bg-transparent"
        />
      )}
      {createPortal(
        <Transition in={open} timeout={150}>
          {(state) => (
            <div
              className="fixed invisible translate-y-12 bg-common-white overflow-hidden z-[1200] shadow-lg"
              style={{
                ...transitions[state],
                top: inputSizing?.y,
                left: inputSizing?.x,
                width: inputSizing?.width
              }}
              data-testid={`${props.testId}-dropdown-wrapper`}
            >
              <FixedSizeList
                itemData={options}
                height={maxListHeight}
                width="100%"
                itemSize={LIST_ITEM_HEIGHT}
                itemCount={options.length}
                children={({ style, data, index: i }) => {
                  const isDisabled = (data[i].listSize ?? 0) >= MAX_LIST_ITEMS;
                  return (
                    <div
                      style={style}
                      data-testid={`${props.testId}-item-${i}`}
                      onClick={onItemSelect(data[i])}
                      className={clsx('select-none pl-4 ', {
                        'bg-primary-2-100/10':
                          data[i].value === selected?.value,
                        'cursor-pointer': !isDisabled,
                        'hover:bg-primary-2-100/20': !isDisabled
                      })}
                    >
                      <li
                        className={clsx('list-none flex justify-between', {
                          'text-secondary-3-100': isDisabled,
                          'text-primary-3-100': !isDisabled
                        })}
                        key={i}
                      >
                        <p className={clsx('py-3 px-4  text-base truncate')}>
                          {data[i].label} ({data[i].listSize})
                        </p>
                        {isDisabled ? (
                          <div className="py-3 px-4">
                            <ListFullWarning showText={true} />
                          </div>
                        ) : null}
                      </li>
                    </div>
                  );
                }}
              />
              {!options.length && (
                <div
                  className="py-2 text-secondary-3-100 text-base text-center"
                  data-testid={`${props.testId}-no-results`}
                >
                  {t('common.noResultsFound')}
                </div>
              )}
            </div>
          )}
        </Transition>,
        document.body
      )}
    </div>
  );
}

export default Autocomplete;
