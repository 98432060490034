import { Divider } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import BranchCard from 'common/Branches/BranchCard';
import BranchCardLoading from 'common/Branches/BranchCardLoading';
import { MAX_BRANCH_DISTANCE, PAGE_SIZE } from 'common/Branches/lib/config';
import { useSidebarLogics } from 'common/Branches/lib/useSidebarLogics';
import Sidebar from 'common/Sidebar';
import { Button, Loader, SelectInput } from 'components';
import { BranchIcon, WarningIcon } from 'icons';
import { useBranchContext } from 'providers/BranchProvider';
import { useCartContext } from 'providers/CartProvider';

/**
 * Config
 */
const loadingItems = [...new Array(PAGE_SIZE).keys()];

/**
 * Component
 */
function BranchSidebar() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const {
    availability,
    divisionOptions,
    firstElRef,
    handleApplyDivision,
    handleClose,
    handleSeeMoreClicked,
    lastElRef,
    inventoryLoading,
    resultCount,
    showSeeMore,
    slicedResults
  } = useSidebarLogics();

  /**
   * Context
   */
  const {
    branchSelectOpen,
    homeBranch,
    homeBranchLoading,
    nearbyBranches,
    nearbyBranchesLoading,
    shippingBranchLoading,
    division,
    productId,
    setShippingBranch
  } = useBranchContext();
  const { itemCount } = useCartContext();
  const homeBranchLS = JSON.parse(localStorage.getItem('homeBranch') || '{}');
  const branchIdLS = JSON.parse(localStorage.getItem('branchID') || '{}');
  /**
   * Render
   */
  return (
    <Sidebar
      on={branchSelectOpen}
      close={handleClose}
      title={t('branch.changeBranch')}
      isContentLoading={shippingBranchLoading || homeBranchLoading}
      widthOverride={512}
    >
      {/* Cart warning */}
      {itemCount > 0 && (
        <div
          className="border border-secondary-1-100 px-3 py-2 flex items-center gap-3 bg-secondary-1-10"
          data-testid="branchsidebar-warning"
        >
          <WarningIcon className="text-secondary-1-100" />
          <p className="flex-1 text-common-black text-xl font-medium">
            {t('branch.warningMessage')}
          </p>
        </div>
      )}
      <div
        className="px-6 flex-auto overflow-auto"
        data-testid="branchsiderbar-main"
      >
        {/* Loading Overlay */}
        {shippingBranchLoading && (
          <Loader backdrop testId="branchsidebar-loader" />
        )}
        <div ref={firstElRef}>
          {/* HOME Branch */}
          <BranchCard
            branch={homeBranchLS}
            header={t('branch.homeBranch')}
            loading={homeBranchLoading}
            availabilityLoading={inventoryLoading}
            icon={<BranchIcon />}
            setShippingBranch={setShippingBranch}
            stock={
              productId
                ? availability.get(homeBranch?.branchId ?? '')
                : undefined
            }
          />
          {/* Location Types Filter */}
          <div className="md:w-2/3 py-4">
            <SelectInput
              label={t('locationSearch.locationTypesLabel')}
              listKey="branchsidebar-locationtype"
              value={division}
              onChange={handleApplyDivision}
              options={divisionOptions}
            />
          </div>
        </div>
        <Divider />
        {/* Branch List */}
        {nearbyBranchesLoading
          ? loadingItems.map((i) => (
              <div key={i}>
                <BranchCardLoading testId={`${i}`} />
                {i < PAGE_SIZE - 1 && <Divider />}
              </div>
            ))
          : slicedResults.map((branch, i) => (
              <div
                ref={i === resultCount - 1 ? lastElRef : null}
                key={branch.branchId}
              >
                <BranchCard
                  branch={branch}
                  setShippingBranch={setShippingBranch}
                  availabilityLoading={inventoryLoading}
                  stock={
                    productId ? availability.get(branch.branchId) : undefined
                  }
                  header={
                    !i && branch.branchId === branchIdLS
                      ? t('branch.selectedBranch')
                      : ''
                  }
                />
                {i < resultCount - 1 && <Divider />}
              </div>
            ))}
      </div>
      {/* See More */}
      {showSeeMore && (
        <div
          className={clsx('p-4 flex justify-center shadow-surround', {
            'hidden -z-10': homeBranchLoading || nearbyBranchesLoading
          })}
        >
          <Button
            color="lightBlue"
            kind="text"
            onClick={handleSeeMoreClicked}
            data-testid="branchsidebar-button-seemore"
          >
            {t('branch.moreBranches')}
          </Button>
        </div>
      )}
      {!nearbyBranchesLoading && !nearbyBranches?.length && branchSelectOpen && (
        <p
          className="text-error-100 text-base text-center py-4"
          data-testid="branchsidebar-nonearbybranches"
        >
          {t('branch.noNearbyBranches', { radius: MAX_BRANCH_DISTANCE })}
        </p>
      )}
    </Sidebar>
  );
}

export default BranchSidebar;
