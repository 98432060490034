import { useCallback, useEffect, useMemo, useState } from 'react';

import { Hidden } from '@mui/material';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CreateListRequest, ProductsIds } from 'API/types/lists.types';
import {
  Button,
  CheckboxInput,
  CircularProgress,
  IconButton
} from 'components';
import {
  AddToCart,
  BarcodeIcon,
  Copy,
  Delete,
  InfoOutlineIcon,
  RightArrowBaseIcon
} from 'icons';
import { AddedItemInfo } from 'pages/Orders/util/addItemsToCartCb';
import getProductAvailability from 'pages/Lists/lib/getProductAvailability';
import { ListNaming } from 'pages/Lists/provider/hooks/useListActionsForm';
import {
  ListAction,
  SelectedLineItemMap,
  useListsPageContext
} from 'pages/Lists/provider/ListsPageProvider';
import ListCopyDialog from 'pages/Lists/sub/ListCopyDialog';
import ListDeleteDialog from 'pages/Lists/sub/ListDeleteDialog';
import ListFormDialog from 'pages/Lists/sub/ListFormDialog';
import ListMoreActions from 'pages/Lists/sub/ListMoreActions';
import ListsSearchItem from 'pages/Lists/sub/ListSearchItem';
import { useCartContext } from 'providers/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useToastContext } from 'providers/ToastProvider';
import { useApiTrackEvent } from 'API/tealium.api';
import { MAX_LIST_ITEMS } from 'providers/libs/ListsProvider';
import ListFullWarning from 'common/ListsDrawer/sub/ListFullWarning';
/**
 * Component
 */
function ListControls() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { addItemToCart, disableAddToCart } = useCartContext();
  const {
    callCreateList,
    callDeleteProductList,
    callPrintBarcodeList,
    createListLoading,
    deleteListLoading,
    filteredListProducts,
    findProductPricing,
    listProducts,
    openCreateFormDialog,
    pricingLoading,
    productsToAddToList,
    selectedLineItems,
    selectedList,
    selectedListLoading,
    setLists,
    setOpenCreateFormDialog,
    setProductsToAddToList,
    setSelectedLineItems,
    setFileLoading,
    setMobileDrawerOpen
  } = useListsPageContext();
  const { toast } = useToastContext();
  const { push } = useHistory();
  const itemCount = listProducts.length;
  const filteredCount = filteredListProducts.length;
  const { selectedAccounts } = useSelectedAccountsContext();
  const { call: trackAddToCart } = useApiTrackEvent();

  /**
   * State
   */
  const [selectAll, setSelectAll] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [formikInstance, setFormikInstance] =
    useState<FormikProps<ListNaming>>();
  const [currentCallback, setCurrentCallback] = useState<
    (values: ListNaming) => void
  >(() => noop);

  const listFull =
    (selectedList?.list.listLineItemsSize ?? 0) >= MAX_LIST_ITEMS;

  /**
   * Memo
   */
  // 🔵 Memo - selected line item count
  const selectedLineItemValues = useMemo(
    () => Object.values(selectedLineItems),
    [selectedLineItems]
  );
  const selectedLineItemCount = selectedLineItemValues.length;
  // 🔵 Memo - selected line item contains unavailable products
  const hasUnavailableSelectedItems = useMemo(
    () => selectedLineItemValues.some((availability) => !availability),
    [selectedLineItemValues]
  );
  /**
   * Callbacks
   */
  // 🟤 Cb - deselect all
  const deselectAll = useCallback(() => {
    setSelectAll(false);
    setSelectedLineItems({});
  }, [setSelectedLineItems]);
  // 🟤 Cb - select all toggle
  const toggleSelectAll = useCallback(() => {
    // deselect all
    if (selectAll) {
      deselectAll();
      return;
    }
    // select all
    setSelectAll(true);
    const mutableProductList = [...filteredListProducts];
    const map = mutableProductList.reduce<SelectedLineItemMap>(
      (prev, item, i) => {
        const pricing = findProductPricing(i, item.productId);
        const availability = getProductAvailability(
          item.status,
          !pricing && !pricingLoading
        );
        prev[item.productId] = availability;
        return prev;
      },
      {}
    );
    setSelectedLineItems(map);
  }, [
    deselectAll,
    findProductPricing,
    filteredListProducts,
    pricingLoading,
    selectAll,
    setSelectedLineItems
  ]);
  // 🟤 Cb - Added selected items to cart
  const addSelectedItemsToCart = () => {
    // unavailable items
    hasUnavailableSelectedItems &&
      toast({ message: t('lists.unavailableAddToCart'), kind: 'warn' });
    // process selected line items each to add to cart
    const entries = Object.entries(selectedLineItems).reduce<AddedItemInfo[]>(
      (prev, [productId, available]) => {
        // unavailable
        if (!available) {
          return prev;
        }
        // process data
        const product = listProducts.find(
          (item) => item.productId === productId
        );
        const minIncrementQty = product?.minIncrementQty || 1;
        const prodQty = product?.quantity || 1;
        const qty =
          (!(prodQty % minIncrementQty) && prodQty) || minIncrementQty;
        const newItem = {
          productId,
          qty,
          minIncrementQty
        };
        return [...prev, newItem];
      },
      []
    );
    // Add processed products to cart
    addItemToCart(entries);
    //Connerly - track event
    trackAddToCart({
      eventName: 'Lists Page View',
      feature: 'Lists',
      eventData: {
        eventData: [
          { dataKey: 'withTasks', value: 'true' },
          { dataKey: 'taskAddToCart', value: 'Items Added to Cart from List' },
          {
            dataKey: 'productId',
            value: entries.map((entry) => entry.productId).join(',')
          },
          { dataKey: 'List Name', value: selectedList?.list.name ?? '' }
        ]
      }
    });
    toast({
      message: t('lists.addedItemsToCart', { qty: entries.length }),
      kind: 'success',
      button: { display: t('lists.viewCart'), action: () => push('/cart') }
    });
  };
  // 🟤 Cb - Make create copied list call
  const createCopyList = async (values: ListNaming) => {
    // Remove whitespace
    const name = values.name.trim();
    const description = values.description.trim();
    const createParams: CreateListRequest = {
      name,
      description,
      products: productsToAddToList
    };
    callCreateList(createParams)
      .then((res) => {
        // Clear data and close modal on either success or error
        formikInstance?.resetForm();
        // Post-API logics
        showResultMessage(Boolean(res?.lists?.length), name, ListAction.create);
        if (res?.lists?.length) {
          setLists(res.lists);
        }
      })
      .catch(() => showResultMessage(false, name, ListAction.create))
      .finally(() => setOpenCreateFormDialog(false));
  };
  // 🟤 Cb - Close delete list dialog
  const closeDeleteDialog = () => setOpenDeleteDialog(false);
  // 🟤 Cb - Make delete list call
  const removeItemsFromList = async () => {
    if (!selectedList?.list) {
      return;
    }
    const productIds: ProductsIds = {
      products: Object.keys(selectedLineItems)
    };
    callDeleteProductList(selectedList.list.id, productIds)
      .then(() => {
        const message = t('lists.removeItemList', {
          qty: productIds.products.length,
          name: selectedList.list.name
        });
        toast({ message, kind: 'success' });
      })
      .catch(() => toast({ message: t('lists.updateError'), kind: 'error' }))
      .finally(closeDeleteDialog);
  };
  // 🟤 Cb - Show error or sucess message
  const showResultMessage = (
    success: boolean,
    name: string,
    change: ListAction
  ) => {
    // Show message
    toast({
      message: t(success ? 'lists.actionSuccess' : 'lists.actionFail', {
        name,
        change
      }),
      kind: success ? 'success' : 'error'
    });
  };

  // 🟤 Cb - Make submit call
  const handleFormSubmit =
    (callback: (values: ListNaming) => Promise<void>) => () => {
      setCurrentCallback(() => callback);
      formikInstance?.submitForm();
    };

  // 🟤 Cb - Close create dialog
  const handleCloseDialog = () => {
    setOpenCreateFormDialog(false);
    setProductsToAddToList([]);
    formikInstance?.resetForm();
  };
  // 🟤 Cb - Menu item - Download List
  const handlePrintBarcodeList = () => {
    setFileLoading(true);
    const name = selectedList?.list.name.trim() ?? '';
    const listId = selectedList?.list.id ?? '';
    const body: ProductsIds = {
      products: Object.keys(selectedLineItems).filter(
        (id) => selectedLineItems[id]
      )
    };
    callPrintBarcodeList(
      listId,
      {
        listName: name,
        billToId: selectedAccounts.billTo?.id ?? '',
        companyName: selectedAccounts.shipToErpAccount?.companyName ?? ''
      },
      body
    )
      .then((response) =>
        showResultMessage(response?.status === 200, name, ListAction.print)
      )
      .finally(() => setFileLoading(false))
      .catch(() => toast({ message: t('lists.networkError'), kind: 'error' }));
  };

  /**
   * Effect
   */
  // 🟡 Effect - get out of select all when select count is less than lineitem count
  useEffect(() => {
    if (selectedLineItemCount < filteredCount && selectAll) {
      setSelectAll(false);
    }
    if (filteredCount && selectedLineItemCount >= filteredCount && !selectAll) {
      setSelectAll(true);
    }
  }, [filteredCount, itemCount, selectAll, selectedLineItemCount]);

  /**
   * Render
   */
  return (
    <div data-testid="lists-name-section" className="md:px-2">
      <Hidden mdUp>
        <div className="flex-1 flex items-center mb-4 justify-between">
          <Button
            type="button"
            data-testid="list-controls-show-lists-panel"
            className="flex !px-2 gap-2 hover:bg-transparent !text-current bg-transparent font-bold text-md"
            onClick={() => setMobileDrawerOpen(true)}
          >
            <RightArrowBaseIcon />
            {t('lists.showListsPanel')}
          </Button>
          <ListMoreActions horizontal={85} list={selectedList?.list} />
        </div>
      </Hidden>
      <div className="flex items-center gap-4">
        <div className="flex flex-1 flex-row items-center gap-2">
          <h3
            className="text-2xl font-medium line-clamp-1"
            data-testid="lists-name-section-name"
          >
            {selectedList?.list.name || t('common.na')}
          </h3>
          <h3 className="text-2xl font-medium">
            {!selectedListLoading && `(${itemCount})`}
          </h3>
          {listFull && <ListFullWarning showText={true} />}
        </div>
        <Hidden mdDown>
          <ListMoreActions showText horizontal={85} list={selectedList?.list} />
        </Hidden>
      </div>
      <div
        className="my-4 py-2.5 flex items-center max-w-[70%] md:!max-w-full"
        data-testid="lists-name-section-description"
      >
        <p
          className={clsx('text-base flex-1 break-all', {
            'text-secondary-3-100': !selectedList?.list.description
          })}
        >
          {selectedList?.list.description || t('lists.noDescription')}
        </p>
      </div>
      <div className="flex flex-nowrap item-center gap-4 mb-2">
        <ListsSearchItem deselectAll={deselectAll} />
      </div>
      {/* tip */}
      <div
        data-testid="list-items-search-tip"
        className="flex flex-1 text-xs text-secondary-2-70 items-center py-2 pb-4 gap-2"
      >
        <InfoOutlineIcon className="!h-4" />
        <p>{t('lists.tipDesktop')}</p>
      </div>
      {/* tools */}
      <div className="flex flex-nowrap item-center gap-2 py-2 px-3 border border-solid border-secondary-3-100 bg-secondary-4-30 rounded-t">
        <div className="flex-1 flex item-center justify-between md:flex-col md:gap-y-2 md:pt-2">
          <CheckboxInput
            label={t('lists.selectAll', { count: filteredCount })}
            labelClassName="text-primary-3-100"
            className="!text-sm ml-1"
            checked={selectAll}
            onChange={toggleSelectAll}
            color="secondary"
            disabled={!itemCount}
            data-testid="list-select-all-checkbox"
          />
          <span className="flex item-center md:justify-between">
            <IconButton
              size="small"
              disabled={!selectedLineItemCount}
              className={clsx('flex-1 gap-1 !text-sm !rounded-lg', {
                '!text-secondary-3-100': !selectedLineItemCount
              })}
              color="inherit"
              testId="list-print-button"
              onClick={handlePrintBarcodeList}
            >
              <BarcodeIcon />
              <span className="md:hidden">{t('lists.printItem')}</span>
            </IconButton>
            <IconButton
              size="small"
              disabled={!selectedLineItemCount || disableAddToCart}
              className={clsx('flex-1 gap-1 !text-sm !rounded-lg', {
                '!text-secondary-3-100':
                  !selectedLineItemCount || disableAddToCart
              })}
              color="inherit"
              testId="list-add-to-cart-button"
              onClick={addSelectedItemsToCart}
            >
              <AddToCart />
              <span className="md:hidden">{t('lists.addItem')}</span>
            </IconButton>
            <IconButton
              size="small"
              disabled={!selectedLineItemCount}
              className={clsx('flex-1 gap-1 !text-sm !rounded-lg', {
                '!text-secondary-3-100': !selectedLineItemCount
              })}
              color="inherit"
              testId="list-copy-button"
              onClick={() => setOpenCopyDialog(true)}
            >
              <Copy />
              <span className="md:hidden">{t('lists.copyItem')}</span>
            </IconButton>
            <IconButton
              size="small"
              disabled={!selectedLineItemCount}
              className={clsx('flex-1 gap-1 !text-sm !rounded-lg', {
                '!text-secondary-3-100': !selectedLineItemCount
              })}
              color="inherit"
              testId="list-delete-button"
              onClick={() => setOpenDeleteDialog(true)}
            >
              <Delete />
              <span className="md:hidden">{t('lists.removeItem')}</span>
            </IconButton>
          </span>
        </div>
        <ListCopyDialog
          open={openCopyDialog}
          onClose={() => setOpenCopyDialog(false)}
        />
        <ListFormDialog
          initialValues={{ name: '', description: '' }}
          setFormikInstance={setFormikInstance}
          open={openCreateFormDialog}
          onClose={handleCloseDialog}
          onSubmitCallback={currentCallback}
          title={t('lists.copyProductNewList')}
          loading={createListLoading}
          actionType={ListAction.create}
        >
          <Button
            type="submit"
            data-testid="list-dialog-form-create-copy-button"
            fullWidth
            className="mb-4"
            onClick={handleFormSubmit(createCopyList)}
            disabled={createListLoading || !productsToAddToList.length}
          >
            {createListLoading ? (
              <CircularProgress size={24} color="secondary" />
            ) : (
              t('lists.copyNewList')
            )}
          </Button>
        </ListFormDialog>
        <ListDeleteDialog
          open={openDeleteDialog}
          onClose={closeDeleteDialog}
          title={t('lists.removeItemConfirmation')}
          deleteButtonLabel={t('common.remove')}
          description={t('lists.removeItemDescription')}
          onClick={removeItemsFromList}
          loading={deleteListLoading}
        />
      </div>
    </div>
  );
}

export default ListControls;
