import { Dispatch, useMemo } from 'react';

import { OverlayView } from '@react-google-maps/api';
import clsx from 'clsx';
import { omit } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { DataMap } from '@reece/global-types';
import { Branch, FindBranchesQuery, Maybe } from 'generated/graphql';
import { SelectedBranch } from 'pages/LocationSearch/lib/useLocationSearchLogics';

/**
 * Config
 */
export const COMING_SOON = 'Coming Soon';
export const PERMANENTLY_CLOSED = 'Permanently Closed';

/**
 * Types
 */
export type BranchData = FindBranchesQuery['branchSearch']['branches'][0];
export type BranchIndexed = BranchData & { index: number };
// 🔸 NOTE: Props has to be passed manually for it to work properly, do not use `useLocationSearchLogics` in this hook because data won't be updated!
type Props = {
  filteredData: BranchData[];
  googleLoaded: boolean;
  setSelectedBranch: Dispatch<Maybe<SelectedBranch>>;
  selectedBranch: Maybe<SelectedBranch>;
};

/**
 * Component
 */
function useMapMarkers(props: Props) {
  /**
   * Props
   */
  const { filteredData, googleLoaded, selectedBranch, setSelectedBranch } =
    props;

  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Memo
   */
  // 🔵 memo - grouped by location
  const groupedData = useMemo(() => {
    const mappedData: DataMap<BranchIndexed[]> = {};
    filteredData.forEach((data, i) => {
      const lonlat = `${data.longitude},${data.latitude}`;
      const newData: BranchIndexed = { ...data, index: i + 1 };
      !mappedData[lonlat] && (mappedData[lonlat] = []);
      mappedData[lonlat].push(newData);
    });
    return Object.values(mappedData);
  }, [filteredData]);
  // 🔵 memo - Google Map Marker
  const markerMemo = useMemo(
    () =>
      googleLoaded &&
      groupedData.map((branches, idx) => {
        // Config
        const [b] = branches;
        const iconNumbers = branches.map(({ index }) => index).join(' | ');
        const branch = omit(b, 'index') as Branch;
        const isComingSoon = b.businessHours === COMING_SOON;
        const isPermaClosed = b.businessHours === PERMANENTLY_CLOSED;
        const latLng = new google.maps.LatLng(b.latitude, b.longitude);
        const isSelectedBranch =
          selectedBranch?.branch.latitude === b.latitude &&
          selectedBranch?.branch.longitude === b.longitude;
        let division = '';
        b.isBandK && (division = `${t('locationSearch.bandk')} - `);
        b.isHvac && (division = `${t('locationSearch.hvac')} - `);
        b.isPlumbing && (division = `${t('locationSearch.plumbing')} - `);
        b.isWaterworks && (division = `${t('locationSearch.waterworks')} - `);
        // Render
        return (
          <OverlayView
            key={`branch-result-gmap-${b.branchId}-${idx}`}
            position={{ lat: b.latitude, lng: b.longitude }}
            mapPaneName={isSelectedBranch ? 'floatPane' : 'overlayMouseTarget'}
          >
            <div
              className={clsx(
                'cursor-pointer px-4 py-2 rounded origin-bottom -translate-y-[calc(100%+22px)] -translate-x-1/2 drop-shadow',
                {
                  'bg-primary-2-100': !isComingSoon && !isPermaClosed,
                  'bg-success-100': isComingSoon,
                  'bg-support-1-100': isPermaClosed
                }
              )}
              onClick={() => setSelectedBranch({ branch, latLng })}
              data-testid={`maps-marker-${idx}`}
              title={`${division}${b.city}`}
            >
              <span className="text-common-white text-base text-bold">
                {iconNumbers}
              </span>
              <div
                className={clsx(
                  'absolute w-full left-0 -bottom-4 text-shad text-xl text-center',
                  {
                    'text-primary-2-100': !isComingSoon && !isPermaClosed,
                    'text-success-100': isComingSoon,
                    'text-support-1-100': isPermaClosed
                  }
                )}
              >
                &#9660;
              </div>
            </div>
          </OverlayView>
        );
      }),
    [googleLoaded, groupedData, selectedBranch, setSelectedBranch, t]
  );

  /**
   * Output
   */
  return markerMemo;
}

export default useMapMarkers;
