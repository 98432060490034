import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { ApolloError, makeVar, useReactiveVar } from '@apollo/client';
import { isNil, noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { useApiTrackEvent } from 'API/tealium.api';
import {
  GetLastSelectedAccountsRequest,
  GetLastSelectedAccountsResponse
} from 'API/types/user.types';
import { useApiGetLastSelectedAccounts } from 'API/users.api';
import SelectAccounts from 'common/SelectAccounts';
import useSelectAccountsFormData from 'common/SelectAccounts/lib/useSelectAccountsFormData';
import {
  EcommAccount,
  ErpAccount,
  ErpSystemEnum,
  GetSelectedErpAccountQuery,
  GetSelectedErpAccountsQuery,
  useGetSelectedErpAccountLazyQuery,
  useGetBranchLazyQuery,
  useGetSelectedErpAccountsLazyQuery
} from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useAuthContext } from 'providers/AuthProvider';
import { useToastContext } from 'providers/ToastProvider';
import { trackLoginAction } from 'utils/analytics';
import { timestamp } from 'utils/dates';
import { encryptData } from 'utils/encrypt';

/**
 * Types
 */
export type SelectedAccounts = {
  billTo?: EcommAccount;
  shipTo?: EcommAccount & {
    accountNumber?: string;
    accountName?: string;
  };
  billToErpAccount?: ErpAccount;
  shipToErpAccount?: ErpAccount;
  shippingBranchId?: string;
  erpSystemName?: ErpSystemEnum;
  starDate?: string;
};

export type SelectedAccountsContextType = {
  selectedAccounts: SelectedAccounts;
  updateShippingBranchId: (branchId: string) => void;
  updateAccounts: (billTo?: EcommAccount, shipTo?: EcommAccount) => void;
  clearAccounts: () => void;
  loading: boolean;
  isMincron: boolean;
  isEclipse: boolean;
  waterworksLogin?: boolean;
  defaultAccount?: GetLastSelectedAccountsResponse | null;
  setAccountsModalOpen: (val: boolean) => void;
  setWaterworksLogin?: (val: boolean) => void;
  error?: ApolloError;
};

type SelectedAccountsProviderProps = {
  debug?: string;
  children: React.ReactNode;
};

/**
 * Consts
 */
const allowedPaths = [
  'terms-of-access',
  'privacy-policy',
  'terms-of-sale',
  'do-not-sell-my-info',
  'logout'
];

const selectedAccounts: Partial<SelectedAccounts> = JSON.parse(
  localStorage.getItem('selectedAccounts') || '{}'
);

export const selectedAccountsVar = makeVar<SelectedAccounts>({
  billTo: selectedAccounts.billTo,
  shipTo: selectedAccounts.shipTo,
  shippingBranchId: selectedAccounts.shippingBranchId,
  erpSystemName: selectedAccounts.erpSystemName
});

/**
 * Context
 */
export const SelectedAccountsContext =
  createContext<SelectedAccountsContextType>({
    selectedAccounts: {
      billTo: undefined,
      shipTo: undefined,
      shippingBranchId: undefined,
      erpSystemName: undefined
    },
    updateShippingBranchId: noop,
    updateAccounts: noop,
    clearAccounts: noop,
    defaultAccount: null,
    loading: false,
    isMincron: false,
    isEclipse: false,
    waterworksLogin: false,
    setAccountsModalOpen: noop,
    setWaterworksLogin: noop
  });
export const useSelectedAccountsContext = () =>
  useContext(SelectedAccountsContext);

/**
 * Component
 */
function SelectedAccountsProvider({ children }: SelectedAccountsProviderProps) {
  /**
   * Custom Hooks
   */
  const { brand, isWaterworks } = useDomainInfo();
  const history = useHistory();
  const selectedAccounts = useReactiveVar(selectedAccountsVar);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { toast } = useToastContext();
  const { updateLastSelectedAccounts } = useSelectAccountsFormData();

  /**
   * State
   */
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const [waterworksLogin, setWaterworksLogin] = useState(false);
  const [defaultAccount, setDefaultAccount] =
    useState<GetLastSelectedAccountsResponse | null>(null);

  /**
   * Context
   */
  const {
    authState,
    ecommUser,
    firstName,
    isLoggingOut,
    isUserLoaded,
    lastName,
    user
  } = useAuthContext();

  /**
   * Data
   */
  // 🟣 Query - Get selected erp account (singular)
  const [
    getSelectedErpAccount,
    { loading: getSelectedErpAccountLoading, error: getSelectedErpAccountError }
  ] = useGetSelectedErpAccountLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: onSelectedErpAccountQueryCompleted
  });
  // 🟣 Query - Get selected erp accounts (plural)
  const [
    getSelectedErpAccounts,
    {
      loading: getSelectedErpAccountsLoading,
      error: getSelectedErpAccountsError
    }
  ] = useGetSelectedErpAccountsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: onSelectedErpAccountsQueryCompleted
  });
  // 🟣 Query - Get branch
  const [getBranch] = useGetBranchLazyQuery({
    onCompleted: ({ branch }) =>
      localStorage.setItem('homeBranch', JSON.stringify(branch)),
    fetchPolicy: 'cache-and-network'
  });
  // 🟣 GET - Last selected accounts
  const { call: getLastSelectedAccounts } = useApiGetLastSelectedAccounts({
    onCompleted: ({ data }) => {
      localStorage.setItem('branchID', data.lastSelectedBranch);
      getBranch({ variables: { branchId: data.billToAccount.branchId } });
      setDefaultAccount(data.origin === 'default' ? data : null);
      if (!accountsModalOpen && forceOpenModal && !isLoggingOut) {
        clearAccounts();
        updateAccounts(
          data.billToAccount as EcommAccount,
          data.shipToAccount as EcommAccount,
          data.lastSelectedBranch
        );
        handleSetLastSelectedAccounts(
          data.billToAccount.id,
          data.shipToAccount.id
        );
      }
    }
  });
  // 🟣 POST - tealium track event
  const { call: trackLogin } = useApiTrackEvent();

  /**
   * Memos
   */
  // 🔵 Memo -  shipToParsed
  const shipToParsed = useMemo(() => {
    const shipToDelimited = selectedAccounts.shipTo?.name?.split(' - ') ?? [];
    const accountName = shipToDelimited.join(' - ');
    const accountNumber = shipToDelimited.shift();
    return { accountNumber, accountName };
  }, [selectedAccounts.shipTo]);
  // 🔵 Memo - Force open modal condition
  const forceOpenModal = useMemo(
    () =>
      !selectedAccounts.shipTo?.id &&
      Boolean(authState?.isAuthenticated) &&
      !allowedPaths.some((location) => pathname.includes(location)),
    [authState?.isAuthenticated, pathname, selectedAccounts.shipTo?.id]
  );

  // 🔵 Memo - Encrypted ShipTo and BillTo
  const encryptedBillTo = useMemo(
    () => encryptData(selectedAccounts.billTo?.id ?? ''),
    [selectedAccounts.billTo?.id]
  );
  const encryptedShipTo = useMemo(
    () => encryptData(selectedAccounts.shipTo?.id ?? ''),
    [selectedAccounts.shipTo?.id]
  );

  /**
   * Callbacks
   */
  // 🟤 CB - set last selected accounts
  const handleSetLastSelectedAccounts = (
    billToId: string,
    shipToId: string
  ) => {
    // Set last selected accounts
    const body: GetLastSelectedAccountsRequest = {
      lastSelectedAccountId: billToId,
      lastSelectedShipTo: shipToId
    };
    updateLastSelectedAccounts(body).catch(
      // istanbul ignore next
      () => toast({ message: t('common.messageFail'), kind: 'error' })
    );
  };
  // 🟤 CB - update accounts
  const updateAccounts = (
    billTo?: EcommAccount,
    shipTo?: EcommAccount,
    branchId = ''
  ) => {
    const updated = selectedAccountsVar({
      ...selectedAccounts,
      billTo,
      shipTo: shipTo ?? billTo,
      erpSystemName: billTo?.erpSystemName ?? undefined,
      shippingBranchId: branchId || undefined // Only affect shippingBranchId if branchId is not empty
    });
    storeSelectedAccounts(updated);
    if (waterworksLogin) {
      setWaterworksLogin?.(false);
      history.push('/invoices');
    }
  };
  // 🟤 CB - clear accounts
  const clearAccounts = () => {
    selectedAccountsVar({});
    storeSelectedAccounts({});
  };
  // 🟤 CB - update shippingBranchId
  const updateShippingBranchId = (shippingBranchId: string) => {
    const updated = selectedAccountsVar({
      ...selectedAccounts,
      shippingBranchId
    });
    storeSelectedAccounts(updated);
  };

  /**
   * Effects
   */
  // 🟡 Effect - Check for selected accounts in required screens
  useEffect(() => {
    if (isUserLoaded || accountsModalOpen) {
      getLastSelectedAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoaded, accountsModalOpen]);
  // 🟡 Effect - Update erp accounts whenever bill to or ship to is changed
  useEffect(() => {
    const { billTo, shipTo } = selectedAccounts;
    // if it is waterworks, the ship to is the bill to
    if (isWaterworks && billTo?.id) {
      getSelectedErpAccount({
        variables: { accountId: encryptedBillTo, brand }
      });
    }
    if (!isWaterworks && billTo?.id && shipTo?.id) {
      getSelectedErpAccounts({
        variables: {
          brand,
          billToId: encryptedBillTo,
          shipToId: encryptedShipTo
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    brand,
    isWaterworks,
    selectedAccounts.billTo?.id,
    selectedAccounts.shipTo?.id
  ]);

  /**
   * Render
   */
  return (
    <SelectedAccountsContext.Provider
      value={{
        selectedAccounts: {
          ...selectedAccounts,
          shipTo: { ...selectedAccounts.shipTo, ...shipToParsed },
          erpSystemName: selectedAccounts.billTo?.erpSystemName ?? undefined
        },
        updateAccounts,
        clearAccounts,
        updateShippingBranchId,
        loading: getSelectedErpAccountLoading || getSelectedErpAccountsLoading,
        isMincron: selectedAccounts.erpSystemName === ErpSystemEnum.Mincron,
        isEclipse: selectedAccounts.erpSystemName === ErpSystemEnum.Eclipse,
        error: getSelectedErpAccountError || getSelectedErpAccountsError,
        waterworksLogin,
        defaultAccount,
        setAccountsModalOpen,
        setWaterworksLogin
      }}
    >
      <SelectAccounts open={accountsModalOpen} required={forceOpenModal} />
      {children}
    </SelectedAccountsContext.Provider>
  );

  /**
   * GQL Complete Defs
   */
  // 🟢 - get erp accounts (singular)
  function onSelectedErpAccountQueryCompleted(
    data: GetSelectedErpAccountQuery
  ) {
    const { billTo } = selectedAccounts;
    const account = data?.account?.find(
      ({ erpName }) => erpName === billTo?.erpSystemName
    );
    isNil(localStorage.getItem('firstLogin')) &&
      trackLogin({
        eventName: 'User Login',
        feature: 'Login',
        eventData: { eventData: [{ dataKey: 'Implementation', value: 'BFF' }] }
      });

    updateErpAccounts(account, account);
  }

  // 🟢 - get erp accounts (plural)
  function onSelectedErpAccountsQueryCompleted(
    data: GetSelectedErpAccountsQuery
  ) {
    const { billTo } = selectedAccounts;
    const billToErpAccount = data?.billToAccount?.find(
      ({ erpName }) => erpName === billTo?.erpSystemName
    );

    const shipToErpAccount = data?.shipToAccount?.find(
      ({ erpName }) => erpName === billTo?.erpSystemName
    );
    isNil(localStorage.getItem('firstLogin')) &&
      trackLogin({
        eventName: 'User Login',
        feature: 'Login',
        eventData: { eventData: [{ dataKey: 'Implementation', value: 'BFF' }] }
      });
    updateErpAccounts(billToErpAccount, shipToErpAccount);
  }

  /**
   * Util
   */
  // 🔣 util - store selectedAccounts to local storage
  function storeSelectedAccounts(data: SelectedAccounts) {
    localStorage.setItem('selectedAccounts', JSON.stringify(data));
  }

  // 🔣 util - onComplete update account logic
  function updateErpAccounts(
    billToErpAccount?: ErpAccount,
    shipToErpAccount?: ErpAccount
  ) {
    let updated = { ...selectedAccounts };

    if (billToErpAccount) {
      const isMincron = billToErpAccount.erp === ErpSystemEnum.Mincron;
      updated.billToErpAccount = billToErpAccount;

      if (isMincron) {
        updated.shippingBranchId = billToErpAccount.branchId ?? '';
      }
      localStorage.setItem('homeBranchID', billToErpAccount.branchId ?? '');
      if (
        isUserLoaded &&
        authState?.isAuthenticated &&
        isNil(localStorage.getItem('firstLogin'))
      ) {
        trackLoginAction({
          billTo: updated.billTo?.erpAccountId,
          homeBranch: billToErpAccount.branchId,
          authenticated: authState?.isAuthenticated,
          userEmail: user?.email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: ecommUser?.phoneNumber,
          brandAssociation: brand,
          region: billToErpAccount.state,
          customerType: ecommUser?.role?.name,
          timestamp: timestamp
        });
        // set first login flag to prevent multiple calls caused by user refresh page
        localStorage.setItem('firstLogin', '1');
      }
    }

    if (shipToErpAccount) {
      const isMincron = shipToErpAccount?.erp === ErpSystemEnum.Mincron;
      updated.shipToErpAccount = shipToErpAccount;
      if (!isMincron) {
        updated.shippingBranchId = shipToErpAccount.branchId ?? '';
      }
    }

    storeSelectedAccounts(updated);
    selectedAccountsVar(updated);
  }
}

export default SelectedAccountsProvider;
