import { useMemo } from 'react';

import { Link } from '@mui/material';
import { InfoWindow } from '@react-google-maps/api';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Branch } from 'generated/graphql';
import { COMING_SOON } from 'pages/LocationSearch/lib/useMapMarkers';

/**
 * Config
 */
const GMAPS_DIRECTION_URL =
  'https://www.google.com/maps/dir/?api=1&destination=';

/**
 * Types
 */
export type PopoverProps = {
  branch: Branch;
  position: google.maps.LatLng;
  onCloseClick: () => void;
};

/**
 * Component
 */
function Popover(props: PopoverProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Memos
   */
  // 🔵 Memo - Directions
  const directionsUrl = useMemo(() => {
    const addressList = [
      props.branch.address1,
      props.branch.city,
      props.branch.state,
      props.branch.zip
    ];
    props.branch.address2 && addressList.splice(1, 0, props.branch.address2);
    const address = addressList.join(' ');
    return `${GMAPS_DIRECTION_URL}${encodeURIComponent(address)}`;
  }, [props.branch]);
  // 🔵 Memo - Address
  const address = useMemo(() => {
    const secondaryAddress = props.branch.address2
      ? ` ${props.branch.address2}`
      : '';
    const cityStateZip = `, ${props.branch.city}, ${props.branch.state} ${props.branch.zip}`;
    return `${props.branch.address1}${secondaryAddress}${cityStateZip}`;
  }, [
    props.branch.address1,
    props.branch.address2,
    props.branch.city,
    props.branch.state,
    props.branch.zip
  ]);
  // 🔵 Memo - Hours
  const businessHours = useMemo(
    () =>
      props.branch.businessHours?.split('; ').map((hour, index) => (
        <p
          key={index}
          className={clsx('text-sm font-normal', {
            'text-primary-3-100': hour !== COMING_SOON,
            'text-success-100': hour === COMING_SOON
          })}
        >
          {hour.replace(':', '')}
        </p>
      )) ?? (
        <p className="text-sm font-normal">{t('locationSearch.noHours')}</p>
      ),
    [props.branch.businessHours, t]
  );

  /**
   * Render
   */
  return (
    <InfoWindow
      position={{
        lat: props.position.lat?.() ?? 0,
        lng: props.position.lng?.() ?? 0
      }}
      onCloseClick={props.onCloseClick}
      options={{ pixelOffset: new google.maps.Size(0, -64) }}
    >
      <div
        className="overflow-hidden px-4 py-2 flex flex-col gap-2"
        data-testid={`google-maps-box-${props.branch.branchId}`}
      >
        <p
          className="text-primary-1-100 text-xl font-medium"
          data-testid={`google-maps-branch-name-${props.branch.branchId}`}
        >
          {props.branch.name}
        </p>
        <p
          className="text-sm font-normal"
          data-testid={`google-maps-branch-address-${props.branch.branchId}`}
        >
          {address}
        </p>
        <div className="flex gap-2">
          <p className="text-sm font-normal">{t('common.hours')}:</p>
          <div data-testid="google-maps-branch-hours">{businessHours}</div>
        </div>
        <Link
          href={`tel:${props.branch.phone}`}
          className="!text-primary-2-100 font-medium"
          data-testid={`google-maps-branch-phone-${props.branch.branchId}`}
        >
          {props.branch.phone}
        </Link>
        <div className="flex items-end">
          <div className="flex-grow">
            <Link
              href={directionsUrl}
              target="_blank"
              rel="noreferer"
              className="pt-3 !text-primary-2-100"
              data-testid={`google-maps-branch-directions-${props.branch.branchId}`}
            >
              {t('locationSearch.directions')}
            </Link>
          </div>
          <p
            data-testid={`google-maps-branch-distance-${props.branch.branchId}`}
          >
            {`${props.branch?.distance?.toFixed(1)} ${t('common.miles')}`}
          </p>
        </div>
      </div>
    </InfoWindow>
  );
}

export default Popover;
