import { useMemo, useState } from 'react';

import { IconButton } from '@dialexa/reece-component-library';
import { Collapse, Link as MuiLink, Skeleton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button, Link } from 'components';
import { Branch } from 'generated/graphql';
import { EHG_SCHEDULE_URL } from 'Home/Landing';
import { ChevronUpIcon, ChevronDownIcon, LocationBubbleIcon } from 'icons';
import { SelectedBranch } from 'pages/LocationSearch/lib/useLocationSearchLogics';
import { COMING_SOON } from 'pages/LocationSearch/lib/useMapMarkers';

/**
 * Types
 */
export type LocationCardProps = {
  loading?: boolean;
  branch?: Branch;
  index: number;
  updateSelectedBranch?: (selectedBranch: SelectedBranch) => void;
};

/**
 * Component
 */
function LocationCard(props: LocationCardProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * State
   */
  const [locationDetails, setLocationDetails] = useState(false);

  /**
   * Memo
   */
  // 🔵 Memo - Business Hours
  const businessHours = useMemo(
    () =>
      props.branch?.businessHours?.split('; ').map((hour, index) => (
        <p
          key={index}
          className={clsx({
            'text-primary-3-100': hour !== COMING_SOON,
            'text-success-100': hour === COMING_SOON,
            'mt-1': index !== 0,
            'mt-2': index === 0
          })}
          data-testid={`branch-hours-${props.index}`}
        >
          {hour.replace(':', '')}
        </p>
      )) ?? <p className="mt-2">{t('locationSearch.noHours')}</p>,
    [props.branch?.businessHours, props.index, t]
  );

  /**
   * Render
   */
  return (
    <div
      className="flex gap-2"
      data-testid={`branch-number-in-list-${props.branch?.branchId}`}
    >
      <IconButton
        onClick={() => {
          props?.updateSelectedBranch?.({
            branch: props.branch as Branch,
            latLng: new google.maps.LatLng(
              props?.branch?.latitude!,
              props?.branch?.longitude!
            )
          });
        }}
        size="small"
        data-testid="branch-icon-button"
        className="!items-start self-start !p-0"
      >
        <div className="relative">
          <LocationBubbleIcon />
          <div
            className="absolute top-0 w-[26px] text-primary-2-100 text-center"
            data-testid={`branch-icon-${props.index}`}
          >
            {props.index}
          </div>
        </div>
      </IconButton>
      <div className="flex flex-col flex-grow gap-2">
        <Button
          data-testid={`${props.branch?.name}-button`}
          disabled={props.loading}
          onClick={() => {
            props?.updateSelectedBranch?.({
              branch: props.branch as Branch,
              latLng: new google.maps.LatLng(
                props?.branch?.latitude!,
                props?.branch?.longitude!
              )
            });
          }}
          className="!p-0 !text-base whitespace-nowrap !justify-start"
          kind="text"
          size="sm"
        >
          <h5
            className="text-primary-1-100 text-xl font-medium"
            data-testid={`branch-name-${props.index}`}
          >
            {props.loading ? <Skeleton /> : props.branch?.name}
          </h5>
        </Button>

        <div className="flex flex-col">
          <p
            className="text-primary-1-100 text-sm"
            data-testid={`branch-address-one-${props.index}`}
          >
            {props.loading ? <Skeleton /> : props.branch?.address1}
          </p>
          <p
            className="text-primary-1-100 text-sm"
            data-testid={`branch-address-two-${props.index}`}
          >
            {props.loading ? <Skeleton /> : props.branch?.address2}
          </p>
          <p
            className="text-primary-1-100 text-sm"
            data-testid={`city-state-zipcode-${props.index}`}
          >
            {props.loading ? (
              <Skeleton />
            ) : (
              `${props.branch?.city}, ${props.branch?.state} ${props.branch?.zip}`
            )}
          </p>
        </div>
        {Boolean(props.branch?.isBandK) && (
          <div className="flex items-end flex-grow">
            <Link to={EHG_SCHEDULE_URL}>
              <Button size="sm" className="whitespace-nowrap">
                {t('home.scheduleNow')}
              </Button>
            </Link>
          </div>
        )}
        {props.loading ? (
          <Skeleton />
        ) : (
          Boolean(props.branch?.phone || props.branch?.businessHours) && (
            <Collapse in={locationDetails}>
              <div className="flex gap-2 text-base">
                <span className="text-primary-2-100 font-bold mt-2">
                  {t('common.hours')}:
                </span>
                <span>{businessHours}</span>
              </div>
              <div className="flex gap-2 text-base">
                <span className="text-primary-2-100 font-bold mt-2">
                  {t('common.phone')}:
                </span>
                <MuiLink
                  href={`tel:${props.branch?.phone}`}
                  className="!mt-2 !text-primary-3-100 font-normal"
                  data-testid={`branch-phone-${props.index}`}
                >
                  {props.branch?.phone}
                </MuiLink>
              </div>
            </Collapse>
          )
        )}
        <div className="flex">
          <Button
            data-testid={`${props.branch?.branchId}${
              locationDetails ? `-hide-details` : `-view-location-details`
            }`}
            disabled={props.loading}
            onClick={() => setLocationDetails(!locationDetails)}
            color="gray"
            iconStart={
              locationDetails ? <ChevronUpIcon /> : <ChevronDownIcon />
            }
            className={clsx('mt-2 !p-0 !text-base whitespace-nowrap', {
              '!text-primary-1-100 !font-normal':
                locationDetails && !props.loading,
              '!text-primary-2-100': !locationDetails && !props.loading,
              '!text-secondary-3-100': props.loading
            })}
            kind="text"
            size="sm"
          >
            {locationDetails
              ? t('locationSearch.hideDetails')
              : t('locationSearch.locationDetails')}
          </Button>
        </div>
      </div>
      <div
        className="flex items-center justify-end pr-2"
        data-testid={`branch-distance-${props.index}`}
      >
        {props.loading ? (
          <Skeleton className="w-16" />
        ) : (
          `${props.branch?.distance?.toFixed(1)} ${t('common.miles')}`
        )}
      </div>
    </div>
  );
}

export default LocationCard;
