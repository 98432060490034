import { t } from 'i18next';
import { WarningIcon } from 'icons';
type ListFullWarningProps = {
  showText: boolean;
};
function ListFullWarning(props: ListFullWarningProps) {
  return (
    <div className="flex">
      <WarningIcon className=" text-secondary-1-100 pr-1 pb-1" />
      {props.showText ? (
        <p className="text-secondary-2 pt-0.5">{t('lists.listFull')}</p>
      ) : null}
    </div>
  );
}

export default ListFullWarning;
