import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import {
  GetCheckoutResponse,
  RapidDeliveryRequest,
  RapidDeliveryResponse,
  UpdateDeliveryRequest,
  UpdateWDateRequest
} from 'API/types/checkout.types';
import {
  CreditCard,
  OrderPreviewResponse,
  OrderResponse
} from 'generated/graphql';

/**
 * Hooks
 */
// 🟩 POST /account/rapidDeliveryCheck - Check Rapid Delivery availability for checkout
export function useApiRapidDeliveryCheck(
  options: APIOptions<RapidDeliveryResponse> = {}
) {
  const props: UseAPILazyProps<RapidDeliveryResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<RapidDeliveryResponse>(props);

  const call = async (cartId: string, body: RapidDeliveryRequest) =>
    await api.call({
      ...props,
      url: `account/rapidDeliveryCheck/${cartId}`,
      body
    });
  return { ...api, call };
}
// 🟦 GET /checkout/getCheckout - Get Checkout
export function useApiGetCheckout(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string) =>
    await api.call({
      ...props,
      url: `checkout/getCheckout/${cartId}`
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateIsRapid - update isRapid
export function useApiUpdateIsRapid(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string, deliveryType: string) =>
    await api.call({
      ...props,
      url: `checkout/updateIsRapid/${cartId}`,
      header: {
        deliveryType: deliveryType === 'Rapid'
      }
    });
  return { ...api, call };
}
// 🟦 GET /checkout/getCheckoutTotals - Get Checkout Totals
export function useApiGetCheckoutTotals(
  options: APIOptions<OrderPreviewResponse> = {}
) {
  const props: UseAPILazyProps<OrderPreviewResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<OrderPreviewResponse>(props);

  const call = async (cartId: string) =>
    await api.call({
      ...props,
      url: `checkout/getCheckoutTotals/${cartId}`
    });
  return { ...api, call };
}
// 🟩 POST /checkout/checkoutSubmit - submit checkout
export function useApiCheckoutSubmit(options: APIOptions<OrderResponse> = {}) {
  const props: UseAPILazyProps<OrderResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<OrderResponse>(props);

  const call = async (cartId: string) =>
    await api.call({
      ...props,
      url: `checkout/submit/${cartId}`
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateDeliveryInstructions - Update Delivery Instructions
export function useApiUpdateDeliveryInstructions(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string, instructions: string) =>
    await api.call({
      ...props,
      url: `checkout/updateDeliveryInstructions/${cartId}`,
      header: { instructions }
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateDeliveryAddress - Update Delivery Address
export function useApiUpdateDeliveryAddress(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string, body: UpdateDeliveryRequest) =>
    await api.call({
      ...props,
      url: `checkout/updateDeliveryAddress/${cartId}`,
      body
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateWillCallInstructions - Update WillCall Instructions
export function useApiUpdateWillCallInstructions(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = (cartId: string, instructions: string) =>
    api.call({
      ...props,
      url: `checkout/updateWillCallInstructions/${cartId}`,
      header: { instructions }
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateWillCallDate - Update WillCall Date
export function useApiUpdateWillCallDate(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = (cartId: string, body: UpdateWDateRequest) =>
    api.call({
      ...props,
      url: `checkout/updateWillCallDate/${cartId}`,
      body
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updatePoNumber - Update PoNumber
export function useApiUpdatePoNumber(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = (cartId: string, poNumber: string) =>
    api.call({
      ...props,
      url: `checkout/updatePoNumber/${cartId}`,
      header: { poNumber }
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updatePaymentMethod - Update Payment Method
export function useApiUpdatePaymentMethod(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = (cartId: string, paymentMethod: string) =>
    api.call({
      ...props,
      url: `checkout/updatePaymentMethod/${cartId}`,
      header: { paymentMethod }
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateDeliveryDate - Update delivery date
export function useApiUpdateDeliveryDate(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = (cartId: string, body: UpdateWDateRequest) =>
    api.call({
      ...props,
      url: `checkout/updateDeliveryDate/${cartId}`,
      body
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateDeliveryPreferences - Update Delivery Preferences
export function useApiUpdateDeliveryPreferences(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = (cartId: string, shouldShipFullOrder: boolean) =>
    api.call({
      ...props,
      url: `checkout/updateDeliveryPreferences/${cartId}`,
      header: { shouldShipFullOrder }
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateCreditCard - Update Credit Card
export function useApiUpdateCreditCard(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = (cartId: string, body: CreditCard) =>
    api.call({
      ...props,
      url: `checkout/updateCreditCard/${cartId}`,
      body
    });
  return { ...api, call };
}
